.la_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #152a68;
  padding: 20px;
}

.la_title {
  font-size: 90px;
  font-weight: bold;
  color: #fff;
}

.la_description {
  max-width: 500px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
