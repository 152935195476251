.question_container {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.fieldset {
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-self: center;
  border: 1px solid;
  border-bottom: none;
}

div.fieldset:nth-last-child(2) {
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.fieldset:nth-child(even) {
  background-color: #001f3d;
}

.fieldset:nth-child(odd) {
  background-color: #034a91;
}

.legend {
  width: 40%;
  border-right: 1px solid;
  padding: 20px 10px;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}

.option.active {
  border: 2px solid;
  border-radius: 50%;
}

.option {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: large;
}

.questionnaire {
  text-align: center;
  margin-bottom: 20px;
}

.order-list {
  list-style: auto;
  margin: revert;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: revert;
  margin: revert;
  text-align: center;
}

.smoke {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
}

.input-item-radio label {
  padding: 10px;
}

.calendar_input {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_input input {
  border: 1px solid;
  padding: 5px;
  width: 80%;
  outline: none;
  font-size: 16px;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #152a68;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 700px) {
  .order-list {
    display: flex;
    flex-direction: row;
  }
  .order-list li {
    margin: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .question_container {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
