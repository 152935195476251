.board_game_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.board_game {
  border: 1px solid;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  max-height: 500px;
}

.box {
  box-sizing: border-box;
  border: 1px solid;
  width: 25%;
  height: 25%;
  cursor: pointer;
}

.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

.box.active .flipper {
  transform: rotateY(180deg);
}

.box_text {
  text-align: center;
  overflow-wrap: anywhere;
  padding: 5px;
  font-size: 14px;
}

.box.selected {
  background: darkgreen !important;
  color: #fff !important;
}

.box:nth-child(1),
.box:nth-child(3),
.box:nth-child(6),
.box:nth-child(8),
.box:nth-child(9),
.box:nth-child(11),
.box:nth-child(14),
.box:nth-child(16) {
  background: #fff;
  color: black;
}
